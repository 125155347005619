.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: #000000; /* Very dark background */
  color: #b0b0b0; /* Darker text color */
}

.navbar-brand {
  color: #b0b0b0 !important; /* Darker text color */
}

.nav-link {
  position: relative;
  text-decoration: none;
  color: #b0b0b0; /* Darker text color */
}

.nav-link:hover {
  color: #b0b0b0; /* Darker text color */
}

.nav-link::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #b0b0b0; /* Darker underline color */
  transition: width .3s;
}

.nav-link:hover::after {
  width: 100%;
  transition: width .3s;
}

.navbar .navbar-brand {
  margin-right: 0;
}

.navbar .navbar-nav {
  width: 100%;
}

.slide-in-left {
  animation: slide-in-left 2s 1s forwards;
  opacity: 0;
}

.slide-in-right {
  animation: slide-in-right 2s 1s forwards;
  opacity: 0;
}

@keyframes slide-in-left {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
